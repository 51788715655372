<template>
  <!-- 面包屑 -->
  <a-breadcrumb>
    <a-breadcrumb-item>用户管理</a-breadcrumb-item>
    <a-breadcrumb-item>注册用户</a-breadcrumb-item>
  </a-breadcrumb>
  <!-- 功能条 -->
  <div class="tool-view">
    <a-row class="tool-row">
      <a-col :span="6">
        <a-row>
          <!-- 日期： -->
          <a-col :span="6" class="tool-title">日期：</a-col>
          <a-col :span="18">
            <a-range-picker
              style="width: 100%;"
              v-model:value="dateRange"
              :format="dateFormat"
            />
          </a-col>
        </a-row>
      </a-col>
      <a-col :span="6">
        <a-row>
          <!-- UID： -->
          <a-col :span="6" class="tool-title">UID：</a-col>
          <a-col :span="18">
            <a-input v-model:value="uid" style="width: 100%;" placeholder="请输入" />
          </a-col>
        </a-row>
      </a-col>
      <a-col :span="6">
        <a-row>
          <!-- 推广ID： -->
          <a-col :span="6" class="tool-title">推广ID：</a-col>
          <a-col :span="18">
            <a-input v-model:value="promotionID" style="width: 100%;" placeholder="请输入" />
          </a-col>
        </a-row>
      </a-col>
      <a-col :span="6">
        <a-row>
          <!-- 平台： -->
          <a-col :span="6" class="tool-title">平台：</a-col>
          <a-col :span="18">
            <a-select v-model:value="platform" style="width: 100%;" placeholder="请选择">
              <a-select-option :value="0">全部</a-select-option>
              <a-select-option v-for="item in Platforms" :key="item.value" :value="item.value">{{ item.text }}</a-select-option>
            </a-select>
          </a-col>
        </a-row>
      </a-col>
      <a-col :span="6">
        <a-row>
          <!-- 是否充值： -->
          <a-col :span="6" class="tool-title">是否充值：</a-col>
          <a-col :span="18">
            <a-select v-model:value="payment" style="width: 100%;" placeholder="请选择">
              <a-select-option :value="0">全部</a-select-option>
              <a-select-option :value="1">是</a-select-option>
              <a-select-option :value="2">否</a-select-option>
            </a-select>
          </a-col>
        </a-row>
      </a-col>
      <a-col :span="6">
        <a-row>
          <!-- 链路来源： -->
          <a-col :span="6" class="tool-title">链路来源：</a-col>
          <a-col :span="18">
            <a-select v-model:value="linkSource" style="width: 100%;" placeholder="请选择">
              <a-select-option v-for="item in LinkSourceTypes" :key="item.value" :value="item.value">{{ item.text }}</a-select-option>
            </a-select>
          </a-col>
        </a-row>
      </a-col>
      <a-col :span="6">
        <a-row>
          <!-- 操作 -->
          <a-col :span="6" class="tool-title"></a-col>
          <a-col :span="18">
            <a-button type="primary" @click="handleSearch">查询</a-button>
            <a-button style="margin-left: 16px;" @click="handleReset">重置</a-button>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </div>
  <!-- 列表 -->
  <a-table
    class="table-view"
    :data-source="dataSource"
    :columns="columns"
    :row-key="record => record.id"
    :pagination="pagination"
    :loading="isLoading"
    @change="handleTableChange"
  >
    <!-- 自定义行内容 -->
    <template #bodyCell="{ column, record }">
      <!-- 平台 -->
      <template v-if="column.key === 'platform_id'">
        {{ (Platforms.find(item => item.value === record[column.key]) || {}).text || '-' }}
      </template>
      <!-- 推广ID -->
      <template v-if="column.key === 'adv_id'">
        {{ record.adv_mapping_one && record.adv_mapping_one.adv_id || '-' }}
      </template>
      <!-- 充值次数 -->
      <template v-if="column.key === 'order_count'">
        <a class="operation-item" @click="handleDetail(record)">{{ record[column.key] }}</a>
      </template>
      <!-- 累计充值 -->
      <template v-if="column.key === 'order_agent_amount'">
        {{ $pub.KEEP_NUMBER_DECIMAL((record[column.key] || 0) / 100, 2, false, true) }}
      </template>
      <!-- 剩余虚拟币 -->
      <template v-if="column.key === 'coin'">
        {{ record.account.coin }}
      </template>
      <!-- 剩余会员天数 -->
      <template v-if="column.key === 'vip_expire_day'">
        {{ getVipTime(record) }}
      </template>
      <!-- 注册时间 -->
      <template v-if="column.key === 'created_at'">
        <a-tooltip>
          <template #title>{{ record.account.created_at ? record.account.created_at.substring(10, 19) : '-' }}</template>
          {{ record.account.created_at ? record.account.created_at.substring(0, 10) : '-' }}
        </a-tooltip>
      </template>
      <!-- 上次访问 -->
      <template v-if="column.key === 'last_login_time'">
        <a-tooltip>
          <template #title>{{ record.last_login_time ? record.last_login_time.substring(10, 19) : '-' }}</template>
          {{ record.last_login_time ? record.last_login_time.substring(0, 10) : '-' }}
        </a-tooltip>
      </template>
      <!-- 上次充值 -->
      <template v-if="column.key === 'last_recharge_time'">
        {{ record.account.last_recharge_time || '-' }}
      </template>
    </template>
  </a-table>
</template>

<script setup>
import { onBeforeMount, ref, reactive } from 'vue'
import Pub from '@/utils/public'
import { message } from 'ant-design-vue'
import { Platforms, LinkSourceTypes } from '@/utils/constantList'
import dayjs from 'dayjs'
import { customerList } from '@/api/operate'

// 加载
let isLoading = ref(false)
// 日期范围
let dateRange = ref([dayjs().subtract(30, 'day'), dayjs()])
// 日期format
const dateFormat = 'YYYY-MM-DD'
// UID
let uid = ref(undefined)
// 推广ID
let promotionID = ref(undefined)
// 平台
let platform = ref(undefined)
// 支付方式
let payment = ref(undefined)
// 链路来源
let linkSource = ref(1)
// 数据源
let dataSource = ref([])
// 分页信息
let pagination = reactive({
  total: 0,
  current: 1,
  pageSize: 10,
  showSizeChanger: true
})
// 列数据源（付费转化率、权重本期不做）
let columns = ref([
  {
    title: 'UID',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: '昵称',
    dataIndex: 'username',
    key: 'username'
  },
  // {
  //   title: 'openID',
  //   dataIndex: 'openid',
  //   key: 'openid'
  // },
  {
    title: '平台',
    dataIndex: 'platform_id',
    key: 'platform_id'
  },
  {
    title: '推广ID',
    dataIndex: 'adv_id',
    key: 'adv_id'
  },
  {
    title: '充值次数',
    dataIndex: 'order_count',
    key: 'order_count'
  },
  {
    title: '累计充值（元）',
    dataIndex: 'order_agent_amount',
    key: 'order_agent_amount'
    // dataIndex: 'total_amount',
    // key: 'total_amount'
  },
  {
    title: '剩余虚拟币',
    dataIndex: 'coin',
    key: 'coin'
  },
  {
    title: '剩余会员天数',
    dataIndex: 'vip_expire_day',
    key: 'vip_expire_day'
  },
  {
    title: '注册时间',
    dataIndex: 'created_at',
    key: 'created_at'
  },
  {
    title: '上次访问',
    dataIndex: 'last_login_time',
    key: 'last_login_time'
  },
  {
    title: '上次充值',
    dataIndex: 'last_recharge_time',
    key: 'last_recharge_time'
  }
])

// 钩子函数
onBeforeMount (() => {
  // 获取注册用户列表
  getCustomerList()
})

// 获取注册用户列表
function getCustomerList () {
  isLoading.value = true
  const params = {
    start_at: dateRange.value ? `${dateRange.value[0].format(dateFormat)}` : undefined,
    end_at: dateRange.value ? `${dateRange.value[1].format(dateFormat)}` : undefined,
    user_id: uid.value,
    adv_id: promotionID.value,
    platform_id: platform.value,
    is_pay: payment.value,
    source: linkSource.value,
    page: pagination.current,
    page_size: pagination.pageSize
  }
  customerList(params).then(res => {
    isLoading.value = false
    const { code, data, msg } = res
    if (code === 0) {
      dataSource.value = data.data
      pagination.total = data.total
    } else {
      message.error(res.message || msg)
    }
  })
}

// 计算会员到期时间
function getVipTime (record) {
  if (record.account.vip_expire_time) {
    const minute = dayjs(record.account.vip_expire_time).diff(dayjs().format('YYYY-MM-DD HH:mm'), 'minute')
    return minute > 0 ? `${parseInt(minute / 60 / 24)}天${minute % 60}分钟` : '-'
  } else {
    return '-'
  }
}

// 分页处理
function handleTableChange (p, filters, sorter) {
  pagination.current = p.current
  pagination.pageSize = p.pageSize
  getCustomerList()
}

// 查询
function handleSearch () {
  pagination.current = 1
  // 获取列表
  getCustomerList()
}

// 重置
function handleReset () {
  // 筛选项重置
  dateRange.value = null
  uid.value = undefined
  platform.value = undefined
  payment.value = undefined
  linkSource.value = 1
  // 分页重置
  pagination.current = 1
  getCustomerList()
}

// 查看充值详情：新开窗口超链至购买记录页检索该用户
function handleDetail (record) {
  Pub.OPEN_ROUTER('/promotion-purchase-record', {
    id: record.id,
    source: linkSource.value
  })
}
</script>

<style scoped>
.tool-row {
  margin-top: 20px;
  line-height: 60px;
}
.tool-title {
  text-align: right;
}
.table-view {
  margin-top: 30px;
}
.operation-item {
  margin-right: 8px;
}
</style>